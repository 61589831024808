.leaflet-container {
    height: 20vh;
    border-radius: 10px;
  }
  @media only screen and (max-width: 767px) {
    .leaflet-container {
      height: 60vW;
      border-radius: 10px;
    }
  }
  .chip {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #f1f1f1;
  }
  
  .chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .static-map {
    width: 250px;
    height: 250px;
  }