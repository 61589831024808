.text-container {
    overflow: hidden;
    white-space: pre-line;
    line-height: 1.2em;
  }
  
  .collapsed {
    line-height: 1.2em; /* Ajustez selon votre besoin */
    max-height: 3.6em; /* 5 lignes x 1.5em par ligne */
  }

  .button-container {
    display: flex;
    margin-top: 1rem;
  }
  
  .show-more-button {
    background-color: transparent;
    border: none;
    color: rgba(3, 201, 184, 1);
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    transition: color 0.2s;
  }
  
  .show-more-button:hover {
    color: rgba(86, 210, 221, 1);
  }
  